import { storage } from "@/util"

const reducer = (state, action) => {
  storage.setl(action.key, action.value)
  switch(action.key) {
    case "lAddress":
      return { ...state, lAddress: action.value }
    case "lToken":
      return { ...state, lToken: action.value }
    case "lChainId":
      return { ...state, lChainId: action.value }
    case "lCachedProvider":
      return { ...state, lCachedProvider: action.value }
    default:
      return state
  }
}

export default reducer