import { withRouter } from "react-router-dom"
import { Knavbar, Kshadow } from "@/component"
import { useTranslation } from "react-i18next"
import "./styles.less"

const IndexPage = ({ history }) => {
  const { t } = useTranslation()
   
  return (
    <div className="copywriting-page">
      <Knavbar title={t("page.rules.title")} />
      <p className="title">{t("page.rules.subtitle")}</p>
      <p className="session-title">{t("page.rules.sessionTitle1")}</p>
      <p className="content">{t("page.rules.content1")}</p>
      <p className="session-title">{t("page.rules.sessionTitle2")}</p>
      <p className="content">{t("page.rules.content2")}</p>
      <p className="session-title">{t("page.rules.sessionTitle3")}</p>
      <p className="content">{t("page.rules.content3")}</p>
      <p className="session-title">{t("page.rules.sessionTitle4")}</p>
      <p className="content">{t("page.rules.content4")}</p>
      <p className="session-title">{t("page.rules.sessionTitle5")}</p>
      <p className="content">{t("page.rules.content5")}</p>
      <p className="session-title">{t("page.rules.sessionTitle6")}</p>
      <p className="content">{t("page.rules.content6")}</p>
      <br />
      <br />
      <br />
      <br />
      <Kshadow />
    </div>
  )
}
export default withRouter(IndexPage)