/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react"
import { useLocation } from "react-router-dom"
import * as echarts from 'echarts'
import moment from "moment"
import { IMAGE } from "@/assets"
import { storage, mathematics } from "@/util"
import { useLocalStore, useSessionStore } from "@/store"
import { useRequest, useCountdown } from "@/hook"
import { useTranslation } from "react-i18next"
import "./styles.less"

const DATALENGTH = Number(process.env.REACT_APP_KLINE_LENGTH)

const IndexComponent = () => {
  const { t } = useTranslation()
  const { lState } = useLocalStore()
  const { sState, sDispatch } = useSessionStore()
  const location = useLocation()
  const { requestNotError } = useRequest()
  const countdown = useCountdown()
  const { pathname } = location
  const { lToken } = lState
  const { sMetaMaskAddress, sInfoData, sInfoReload } = sState
  const isLogin = !!lToken && !!sMetaMaskAddress
  const [data, setData] = useState([])
  const [xAxisData, setXAxisData] = useState([])
  const [seriesData, setSeriesData] = useState([])
  const [max, setMax] = useState(0)
  const [min, setMin] = useState(0)
  const [reload, setReload] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [originalData, setOriginalData] = useState([])
  const [originalPoint, setOriginalPoint] = useState([])
  const [addPoints, setAddPoints] = useState([])
  const [originalLine, setOriginalLine] = useState([])
  const [addLines, setAddLines] = useState([])
  const [lastFinishedId, setLastFinishedId] = useState("")
  const isStart = pathname.startsWith("/join") || pathname === "/"
  const [gifKey, setGifKey] = useState(0)
  const [isPlaying, setIsPlaying] = useState(false)
  const [progressOrder, setProgressOrder] = useState(null)

  useEffect(() => {
    if (!progressOrder) return
    if (countdown.time !== 0) return
    const endTime = progressOrder.startTime + DATALENGTH * 1000
    const currentTime = (moment().unix() - 5) * 1000
    const duration = Math.min(Math.max((endTime - currentTime) / 1000, 0), DATALENGTH)
    countdown.start(duration)
  }, [progressOrder])

  useEffect(() => {
    if (countdown.time <= 0) {
      setProgressOrder(null)
      sDispatch({key: "sDisableTag", value: 0})
    }
  }, [countdown.time])

  useEffect(() => {
    const timer = setTimeout(() => {
      const showWin = 
      isLogin ?
          lastFinishedId.length !== 0
        :
          lastFinishedId.length !== 0 && `${localStorage.getItem("lLastFinishedId")}` !== `${lastFinishedId}`
      if (showWin) {
        if (!isLogin) localStorage.setItem("lLastFinishedId", lastFinishedId)
        sDispatch({key: "sInfoReload", value: !sInfoReload})
        setGifKey(gifKey + 1)
        setIsPlaying(true)
        setTimeout(() => {
          setIsPlaying(false)
        }, 3000)
      }
    }, 2000)
    return () => {
      clearTimeout(timer)
    }
  }, [lastFinishedId, isLogin])

  useEffect(() => {
    const handleFocus = () => {
      if (!document.hidden) {
        setData([])
        setXAxisData([])
        setSeriesData([])
        setOriginalData([])
      }
    }
    window.addEventListener("visibilitychange", handleFocus)
    return () => {
      window.removeEventListener("focus", handleFocus)
    }
  }, [])

  useEffect(() => {
    async function getOriginalData(symbol) {
      const resp = await requestNotError("fight/get_kline", {
        symbol: symbol,
        interval: '1s',
        startTime: moment().subtract(DATALENGTH + 5, "seconds").valueOf()
      })
      if (!!resp && !resp?.error) {
        const _data = resp.map(subArray => {
          return {
            "timestamp": subArray[0],
            "value": subArray[1],
            "status": true,
          }
        })
        setOriginalData(_data)
      }
    }
    const interval = setInterval(function() {
      if (isStart) {
        getOriginalData("BTCAMM")
      } else if (!!sInfoData?.symbol) {
        getOriginalData(sInfoData?.symbol)
      }
    }, 1000) 
    return () => {
      clearInterval(interval)
    }
  }, [sInfoData?.symbol, isStart])

  useEffect(() => {
    function getData() {
      if (originalData.length === 0) {
        setData([])
      } else if (data.length === 0 || Math.abs(originalData.length - data.length) >= DATALENGTH) {
        const _data = originalData
        const __data = _data.length > DATALENGTH ? _data.slice(0, DATALENGTH) : _data
        setData(__data)
      } else {
        let filteredArray = originalData.filter(function(element) {
          return element.timestamp === data[data.length - 1].timestamp + 1000
        })
        let newData = data.concat()
        if (data.length >= DATALENGTH) {
          newData.shift()
        }
        newData.forEach((element, index) => {
          const _element = originalData.length > index ? originalData[index] : null
          if (_element !== null && element.timestamp === _element.timestamp && !element.status) {
            element.value = _element.value
            element.status = true
          }
        })
        if (filteredArray.length > 0) {
          newData.push(filteredArray[0])
        } else {
          newData.push({
            "timestamp": data[data.length - 1].timestamp + 1000,
            "value": data[data.length - 1].value,
            "status": false,
          })
        }
        const _current = moment().valueOf();
        const _max = data[data.length - 1].timestamp;
        if (_current - _max >= 5000) {
          const filteredArray2 = originalData.filter(function (element) {
            return element.timestamp === data[data.length - 1].timestamp + 2000;
          })
          if (filteredArray2.length > 0) {
            newData.shift()
            newData.push(filteredArray2[0])
          }
        }
        sDispatch({key: "sKlineDatas", value: newData})
        setData(newData)
      }
      setTimeout(() => {
        setReload(!reload)
      }, 1000)
    }
    getData()
}, [reload, DATALENGTH])

useEffect(() => {
  const virtualOrders = storage.getl("virtualOrders") || []
  if (!isLogin && data.length > 0 && virtualOrders.length > 0) {
    virtualOrders.forEach((virtualOrder, index) => {
      if (virtualOrder.startPrice === 0) {
        data.forEach(element => {
          if (element.timestamp === virtualOrder.startTime) {
            virtualOrder.startPrice = element.value
            virtualOrders[index] = virtualOrder
            storage.setl("virtualOrders", virtualOrders)
          }
        })
      }
      if (virtualOrder.endPrice === 0) {
        data.forEach(element => {
          if (element.timestamp === virtualOrder.endTime) {
            virtualOrder.endPrice = element.value
            virtualOrder.userResult = (virtualOrder.direction === "BULL" && virtualOrder.endPrice > virtualOrder.startPrice) || (virtualOrder.direction === "BEAR" && virtualOrder.endPrice < virtualOrder.startPrice) ? "WIN" : "LOSE"
            virtualOrders[index] = virtualOrder
            storage.setl("virtualOrders", virtualOrders)
            sDispatch({key: "sInfoReload", value: !sInfoReload})
          }
        })
      }
    })
  }
}, [data, isLogin])

  useEffect(() => {
    if (data.length > 0) {
      const _seriesData = data.map(subData => subData.value)
      const maxValue = Math.max(..._seriesData)
      const minValue = Math.min(..._seriesData)
      const interval = maxValue - minValue
      const space = interval < 8 ? (8 - interval) * 0.5 : 0
      const _max = mathematics.ceilString(maxValue + space)
      const _min = mathematics.floorString(minValue - space)
      setMax(_max)
      setMin(_min)
      const _xAxisData1 = data.map(subData => {
        const timestamp = subData.timestamp
        const time = moment(timestamp)
        return time.format("mm:ss")
      })
      const _xAxisData2 = data.map(subData => {
        const timestamp = subData.timestamp
        const time = moment(timestamp).add(DATALENGTH, "seconds")
        return time.format("mm:ss")
      })
      const _xAxisData = [..._xAxisData1, ..._xAxisData2]
      setSeriesData(_seriesData)
      setXAxisData(_xAxisData) 
    }
  }, [data])

  useEffect(() => {
    const _originalPoint = [{
      yAxis: seriesData.length === 0 ? 0 : seriesData[seriesData.length - 1],
      xAxis: xAxisData.length === 0 ? 0 : xAxisData[xAxisData.length / 2 - 1],
      symbol: "circle",
      symbolSize: 12,
      itemStyle: {
        color: "#ffffff",
        borderWidth: 10,
        borderColor: "rgba(255, 255, 255, 0.12)"
      },
      animation: true,
      animationDuration: 2000
    }]
    setOriginalPoint(_originalPoint)
  }, [seriesData, xAxisData])

  useEffect(() => {
    const _originalLine = [[
      {
        symbol: 'none',
        label: {
          formatter: `${max}`,
          fontSize: 11,
          color: "rgba(255, 255, 255, 0.50)",
          distance: 15,
        },
        coord: [xAxisData[0], max],
        lineStyle: {
          color: "rgba(255, 255, 255, 0.12)",
          width: 1,
        },
        animation: false
      },
      {
        symbol: 'none',
        coord: [xAxisData[xAxisData.length - 1], max],
        lineStyle: {
          color: "rgba(255, 255, 255, 0.12)",
          width: 1,
        },
        animation: false
      }
    ],[
      {
        symbol: "none",
        label: {
          formatter: `${min}`,
          fontSize: 11,
          color: "rgba(255, 255, 255, 0.50)",
          distance: 15,
        },
        coord: [xAxisData[0], min],
        lineStyle: {
          color: "rgba(255, 255, 255, 0.12)",
          width: 1,
        },
        animation: false
      },
      {
        symbol: "none",
        coord: [xAxisData[xAxisData.length - 1], min],
        lineStyle: {
          color: "rgba(255, 255, 255, 0.12)",
          width: 1,
        },
        animation: false
      }
    ]]
    setOriginalLine(_originalLine)
  }, [max, min, xAxisData])

  useEffect(() => {
    async function getOrder() {
      const virtualOrders = storage.getl("virtualOrders") || []
      const progressResp = 
        isStart ? 
          [] 
        : isLogin ? 
          await requestNotError("fight/playingOrder", {
            status: "PROGRESS",
            type: process.env.REACT_APP_KLINE_TYPE,
            duration: DATALENGTH + 5
          })
        : 
          virtualOrders.filter(virtualOrder => virtualOrder.userResult === "")
        const _progressOrder = 
          isStart ? 
            null
          : isLogin ?
            !!progressResp?.error || progressResp.length === 0 ? 
              null
            : 
              progressResp[progressResp.length - 1]
          :
            !progressResp || progressResp.length === 0 ?
              null
            :
              progressResp[progressResp.length - 1]
        setProgressOrder(_progressOrder)
      const finishedResp = 
        isStart ? 
          []
        : isLogin ? 
          await requestNotError("fight/playingOrder", {
            status: "FINISHED",
            type: process.env.REACT_APP_KLINE_TYPE,
            duration: DATALENGTH + 5
          }) 
        : 
          virtualOrders.filter(virtualOrder => virtualOrder.userResult !== "")
      const WinResp = 
        isLogin ? 
          !!finishedResp && !finishedResp?.error ?
            finishedResp.filter(virtualOrder => virtualOrder.userResult === "WIN")
          :
            []
        :
          !!virtualOrders ?
            virtualOrders.filter(virtualOrder => virtualOrder.userResult === "WIN")
          :
            []
        
      if (!!WinResp && WinResp?.length > 0) setLastFinishedId(isLogin ? WinResp[WinResp?.length - 1].id : WinResp[WinResp?.length - 1].startTime)
      const _addPoints = []
      const _addLines = []
      if (!!progressResp && !progressResp?.error && !!progressResp && progressResp?.length > 0) {
        progressResp.forEach(element => {
          const _startTime = moment(element.startTime)
          const startTime = _startTime.format("mm:ss")
          const price = element.startPrice
          const startSymbol = element.direction === "BULL" ? `image://${IMAGE.upStart}` : `image://${IMAGE.downStart}`
          const _endTime = moment(element.startTime).add(DATALENGTH, 'seconds')
          const endTime = _endTime.format("mm:ss")
          const endSymbol = element.direction === "BULL" ? `image://${IMAGE.upEnd}` : `image://${IMAGE.downEnd}`
          const color = element.direction === "BULL" ? "rgb(39, 207, 165, 0.4)" : "rgb(242, 82, 99, 0.4)"
          const startPoint = {
            xAxis: startTime,
            yAxis: price,
            symbol: startSymbol,
            symbolSize: [20, 20],
            value: element.startPrice,
            label: {
              show: true,
              position: "top",
              fontFamily: "-text-nunito-sans",
              fontSize: 12,
              color: element.direction === "BULL" ? "rgb(39, 207, 165, 1)" : "rgb(242, 82, 99, 1)"
            }
          }
          const endPoint = {
            xAxis: endTime,
            yAxis: price,
            symbol: endSymbol,
            symbolSize: [12, 12],
          }
          const horizontalLine = [
            {
              symbol: "none",
              coord: [startTime, price],
              lineStyle: {
                color: color,
                width: 1,
                type: 0,
              },
              animation: false
            },
            {
              symbol: "none",
              coord: [endTime, price],
              lineStyle: {
                color: color,
                width: 1,
                type: 0,
              },
              animation: false
            }
          ]
          const verticalLine = [
            {
              symbol: "none",
              coord: [endTime, max],
              lineStyle: {
                color: color,
                width: 1,
              },
              animation: false
            },
            {
              symbol: "none",
              coord: [endTime, min],
              lineStyle: {
                color: color,
                width: 1,
              },
              animation: false
            }
          ]
          _addPoints.push(startPoint)
          _addPoints.push(endPoint)
          _addLines.push(horizontalLine)
          _addLines.push(verticalLine)
        })
      }
      if (!!finishedResp && !finishedResp?.error && !!finishedResp && finishedResp.length > 0) {
        finishedResp.forEach(element => {
          const price = element.endPrice
          const _endTime = moment(element.startTime).add(DATALENGTH, "seconds")
          const endTime = _endTime.format("mm:ss")
          const endSymbol = element.userResult === "WIN" ? `image://${IMAGE.win}` : `image://${IMAGE.lose}`  
          const _symbolSize = [32, 32]      
          const endPoint = {
            xAxis: endTime,
            yAxis: price,
            symbol: endSymbol,
            symbolSize: _symbolSize,
            animation: false
          }
          _addPoints.push(endPoint)
        })
      }
      setAddPoints(_addPoints)
      setAddLines(_addLines)
      setTimeout(() => {
        setRefresh(!refresh)
      }, 2000)
    }
    getOrder()
  }, [refresh, max, min, isLogin, pathname])

  useEffect(() => {
    let chartDom = document.getElementById("echartID")
    let myChart = echarts.getInstanceByDom(chartDom)
    if (!myChart) {
      myChart = echarts.init(chartDom)
    }
    var option = {
      grid: {
        left: 0,
        right: 75,
        top: 30,
        bottom: 30,
      },
      xAxis: {
        type: "category",
        data: xAxisData,
        axisLine: {
          show: false
        },
        axisTick: {
          show: false
        },
        axisLabel: {
          fontSize: 11,
          color: function (value, index) {
            return index % 2 === 0 ? "rgba(255, 255, 255, 0.5)" : "transparent"
          },
          margin: 11,
          align: "left",
          // showMaxLabel: true
        }
      },
      yAxis: {
        show: false,
        min: mathematics.floorString(min, 2),
        max: mathematics.floorString(max, 2)
      },
      series: [
        {
          data: seriesData,
          type: "line",
          showSymbol: false,
          areaStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: "rgba(0, 0, 0, 0.00)"
              },
              {
                offset: 1,
                color: "rgba(46, 171, 255, 0.25)"
              }
            ])
          },
          smooth: true,
          lineStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
              {
                offset: 0,
                color: "#2EABFF"
              },
              {
                offset: 1,
                color: "#FFFFFF"
              }
            ]),
            width: 2
          },
          markLine: {
            lineStyle: {
              color: "rgba(255, 255, 255, 0.12)",
              width: 1,
            },
            data: [...originalLine, ...addLines],
            animation: false,
          },
          markPoint: {
            data: [...originalPoint, ...addPoints]
          }
        }
      ],
    }
    myChart.setOption(option)
  }, [xAxisData, seriesData, max, min, originalPoint, addPoints, originalLine, addLines])

  return (
    <div className="echart-component" style={{ transform: isStart ? "scale(0.6)" : "", marginTop: isStart ? "0" : "" }}>
      <div className="head-div">
        <div className="content-div">
          <h1 className="t1">{t("component.echart.BTC.Price")}</h1>
          <h1 className="t2">{`${seriesData.length === 0 ? '-' : `$${mathematics.floorString(parseFloat(seriesData[seriesData.length - 1]), 2)}`}`} 
          {
            seriesData.length > 1 && <span style={{ color: seriesData[seriesData.length - 1] >= seriesData[seriesData.length - 2] ? '#10C29F' : '#FF5151' }}>
              {`${ seriesData[seriesData.length - 1] >= seriesData[seriesData.length - 2] ? '+' : '-' }`}{`${parseFloat(Math.abs(seriesData[seriesData.length - 1] - seriesData[seriesData.length - 2]).toFixed(3))}`}
            </span>
          }
          </h1>
        </div>
        <div className="select-button" style={{ backgroundColor: isStart ? "#31313F" : "#1F1F2E" }}>
          <h2>{countdown.time === 0 ? `${DATALENGTH}s` : `${countdown.time}s`}</h2>
        </div>
      </div>
      <div className="echart-div" id="echartID" style={{ height: isStart ? "200px" : "" }}></div>
      <img key={gifKey} src={IMAGE.winGif} alt="" draggable="false" className="win-gif" style={{ display: isPlaying ? "block" : "none" }} />
    </div>
  )
}
export default IndexComponent
