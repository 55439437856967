import IMAGE from "./image"
import TGBSCHARGEPOOL from "./abi/tgbs-charge-pool"
import TGBSLPPOOL from "./abi/tgbs-lp-pool"
import TGBSSWAP from "./abi/tgbs-swap"
import TOKENERC20 from "./abi/token-erc20"

const ABI = {
  TGBSCHARGEPOOL: TGBSCHARGEPOOL,
  TGBSLPPOOL: TGBSLPPOOL,
  TGBSSWAP: TGBSSWAP,
  TOKENERC20: TOKENERC20
}

export { 
  IMAGE, 
  ABI
}