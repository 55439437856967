import { useState, useRef } from "react"
import { useLocation } from "react-router-dom"
import { Toast } from "antd-mobile"
import moment from "moment"
import { IMAGE } from "@/assets"
import { Kpopup } from "@/component"
import { useLocalStore, useSessionStore } from "@/store"
import { useRequest } from "@/hook"
import { storage, mathematics } from "@/util"
import { useTranslation } from "react-i18next"
import "./styles.less"

const MINAMOUNT = 1000
const MAXAMOUNT = 100000

const IndexComponent = () => {
  const { t } = useTranslation()
  const { lState } = useLocalStore()
  const { sState, sDispatch } = useSessionStore()
  const { request } = useRequest()
  const location = useLocation()
  const { pathname } = location
  const { lToken } = lState
  const { sMetaMaskAddress, sInfoData, sInfoReload, sDisableTag, sCanTrade } = sState
  const isLogin = !!lToken && !!sMetaMaskAddress
  const initAmount = "1000"
  const [amount, setAmount] = useState(initAmount)
  const [visible, setVisible] = useState(false)
  const [inviteVisible, setInviteVisible] = useState(false)
  const [inviteValue, setInviteValue] = useState("")
  const [inviteLoading, setInviteLoading] = useState(false)
  const bottomRef = useRef(null)

  async function order(direction) {
    if (isLogin) {
      const resp = await request("fight/addOrder", {
        asset: sInfoData?.asset, 
        amount: amount, 
        direction: direction
      })
      if (!!resp?.error) {
        sDispatch({key: "sDisableTag", value: 0})
        if (resp.desc.code === 100074) {
          setInviteVisible(true)
        }
        return
      }
      setTimeout(() => {
        sDispatch({key: "sInfoReload", value: !sInfoReload})
      }, 2000)
      // const _orders = !!sProgressOrders ? sProgressOrders : []
      // const _order = sKlineDatas[sKlineDatas.length - 1]
      // const order = {
      //   startTime: _order.timestamp,
      //   endTime: _order.timestamp + Number(process.env.REACT_APP_KLINE_LENGTH) * 1000,
      //   direction: direction,
      //   startPrice: _order.value,
      //   endPrice: 0,
      //   userResult: "UNKNOWN",
      //   amount: parseFloat(amount),
      //   status: "PROGRESSING",
      //   isLocal: true
      // }
      // _orders.push(order)
      // sDispatch({key: "sProgressOrders", value: _orders})
    } else {
      const virtualOrders = storage.getl("virtualOrders") || []
      const timestamp = moment().unix() 
      const order = {
        startTime: timestamp * 1000,
        endTime: (timestamp + Number(process.env.REACT_APP_KLINE_LENGTH)) * 1000,
        direction: direction,
        startPrice: 0,
        endPrice: 0,
        userResult: "",
        amount: parseFloat(amount),
        disable: false,
      }
      virtualOrders.push(order)
      const virtualAmount = virtualOrders.length
      virtualAmount > 0 && virtualAmount % 6 === 0 && setVisible(true)
      storage.setl("virtualOrders", virtualOrders)
      sDispatch({key: "sInfoReload", value: !sInfoReload})
    }
  }

  const onBind = async () => {
    setInviteLoading(true)
    const resp = await request("app/account/bindInvitor", {
      invitorWalletAddress: inviteValue
    })
    setInviteLoading(false)
    if (!!resp?.error) return
    setInviteVisible(false)
    Toast.show({
      icon: "success",
      content: t("component.bet.t1")
    })
  }

  return (
    <div className="beat-component">
      {
        pathname !== '/' && <div className="input-container">
          <div className="info">
            <h1>{ sInfoData?.asset }</h1>
            <h2>{`${t("component.bet.payout")}:`}<span>{mathematics.localeString(parseFloat(amount) * (parseFloat(sInfoData?.payOutRate) + 1))}</span></h2>
          </div>
          <div className="op-div">
            <div className="button-1" onClick={() => setAmount(MINAMOUNT)}>{t("component.bet.min")}</div>
            <img src={IMAGE.reduce} alt="" draggable="false" style={{ 
              opacity: parseFloat(amount) === MINAMOUNT ? '0.25' : '1.0',
              cursor: parseFloat(amount) === MINAMOUNT ? 'not-allowed' : 'pointer' 
            }} onClick={() => {
              if (amount === `${MINAMOUNT}`) {
                return
              } else {
                const _amount = parseFloat(amount) - 1
                const __amount = Math.max(_amount, 1)
                setAmount(`${__amount}`)
              }
            }} />
            <p>{ amount }</p>
            <img src={IMAGE.add} alt="" draggable="false" style={{ 
              opacity: parseFloat(amount) >= MAXAMOUNT ? '0.25' : '1.0', 
              cursor: parseFloat(amount) >= MAXAMOUNT ? 'not-allowed' : 'pointer' 
            }} onClick={() => {
              const _amount = parseFloat(amount) + 1
              const __amount = Math.min(_amount, MAXAMOUNT)
              setAmount(`${__amount}`)
            }} />
            <div className="button-1" onClick={() => setAmount(MAXAMOUNT)}>{t("component.bet.max")}</div>
          </div>
          <div className="op-div">
          {
            [500, 1000, 2500, 10000, 25000].map((item, index) => {
              return <div className="button-1" style={{ flex: 1 }} key={index} onClick={() => {
                const _amount = Math.min(MAXAMOUNT, Number(amount) + item)
                setAmount(_amount)
              }}>{`+${item}`}</div>
            })
          }
          </div>
        </div>
      }
      <div className="buttons-list" style={{ marginBottom: sDisableTag === -1 || sDisableTag === 1 ? '17px' : '21px' }} ref={bottomRef}>
        <div className={`button bull ${
          parseFloat(amount) > sInfoData?.balance || !sCanTrade ? 'empty-bull-disable' 
          : sDisableTag === 1 ? 'touch-bull-disable' 
          : sDisableTag === -1 || pathname === '/' ? 'empty-bull-disable' 
          : ''}`
        } onClick={() => {
          if (sDisableTag !== 0 || pathname === '/' || parseFloat(amount) > sInfoData?.balance || !sCanTrade) return
          sDispatch({key: "sDisableTag", value: 1})
          order("BULL")
        }}>
          <img src={IMAGE.bull} alt="" draggable="false" />
          <div className="desc" style={{ marginRight: '12px' }}>
            <h1>{t("component.bet.up")}</h1>
            <img src={IMAGE.up2} alt="" draggable="false" />
          </div>
        </div>
        <div className={`button bear ${
          parseFloat(amount) > sInfoData?.balance || !sCanTrade ? 'empty-bear-disable' 
          : sDisableTag === -1 ? 'touch-bear-disable' 
          : sDisableTag === 1 || pathname === '/' ? 'empty-bear-disable' 
          : ''}`
        } onClick={() => {
          if (sDisableTag !== 0 || pathname === '/' || parseFloat(amount) > sInfoData?.balance || !sCanTrade) return
          sDispatch({key: "sDisableTag", value: -1})
          order("BEAR")
        }}>
          <div className="desc" style={{ marginLeft: '12px' }}>
            <img src={IMAGE.down2} alt="" draggable="false" />
            <h1>{t("component.bet.down")}</h1>
          </div>
          <img src={IMAGE.bear} alt="" draggable="false" />
        </div>
      </div>
      <Kpopup visible={visible} setVisible={setVisible} type="beat" />
      <Kpopup 
        visible={inviteVisible} 
        setVisible={setInviteVisible} 
        value={inviteValue} 
        setValue={setInviteValue} 
        loading={inviteLoading} 
        type="setInvitor"
        onConfirm={onBind}
      />
    </div>
  )
}
export default IndexComponent