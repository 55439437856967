import { withRouter } from "react-router-dom"
import { Button, Toast } from "antd-mobile"
import CopyToClipboard from "react-copy-to-clipboard"
import { useTranslation } from "react-i18next"
import { useScroll, useRoute } from "@/hook"
import { theString, mathematics } from "@/util"
import { useSessionStore } from "@/store"
import { Knavbar } from "@/component"
import { IMAGE } from "@/assets"
import "./styles.less"

const IndexPage = () => {
  const { t } = useTranslation()
  const { distance } = useScroll()
  const { back } = useRoute()
  const { sState } = useSessionStore()
  const { sMetaMaskAddress, sInfoData } = sState

  return (
    <div className="referral-page">
      <Knavbar title={t("page.referral.title")} fixed hidden={distance < 24} />
      <div className="description">
        <div className="label">
          <img src={IMAGE.back} alt="" draggable="false" className="back" onClick={() => back()} />
          <h1>{t("page.referral.title")}</h1>
          <h2>{t("page.referral.t1")}</h2>
        </div>
        <img src={IMAGE.flower} alt="" draggable="false" className="pic" />
      </div>
      <div className="referral-data">
        <p className="key">{t("page.referral.t2")}</p>
        <p className="value">{mathematics.localeString(sInfoData?.referrals)}</p>
      </div>
      <div className="share-link">
        <img src={IMAGE.link} alt="" draggable="false" />
        <p>{`https://guessing.tsp.network/join/${theString.ellipsisMiddle(sMetaMaskAddress, 4)}`}</p>
      </div>
      <CopyToClipboard
        text={`https://guessing.tsp.network/join/${sMetaMaskAddress}`}
        onCopy={() => Toast.show({
          icon: 'success',
          content: t("page.referral.copied")
        })}
      >
        <Button block className="copy-button">{t("page.referral.copy")}</Button>
      </CopyToClipboard>
    </div>
  )
}
export default withRouter(IndexPage)