/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react"
import { withRouter } from "react-router-dom"
import { Knavbar, Kinfo, Kechart, Kbet, Kshadow, Kmenu, Kliquidity } from "@/component"
import { useLocalStore, useSessionStore } from "@/store"
import { useRequest } from "@/hook"
import "./styles.less"

const IndexPage = () => {
  const { lState } = useLocalStore()
  const { sState, sDispatch } = useSessionStore()
  const { requestNotError, requestVisitorData, requestOfGet } = useRequest()
  const { lToken } = lState
  const { sMetaMaskAddress, sTabIndex, sInfoData, sInfoReload, sChangeOfBalance } = sState
  const isLogin = !!lToken && !!sMetaMaskAddress

  const getInfoData = async () => {
    const resp = await requestNotError(`fight/info/v2?type=${process.env.REACT_APP_KLINE_TYPE}`)
    if (!!resp?.error) return
    if (sChangeOfBalance) {
      if (resp.balance === sInfoData.balance) {
        setTimeout(() => {
          sDispatch({key: "sInfoReload", value: !sInfoReload})
        }, 1000)
      } else {
        sDispatch({key: "sChangeOfBalance", value: false})
      }
    }
    sDispatch({key: "sInfoData", value: resp})
  }

  useEffect(() => {
    const getStatus = async () => {
      const resp = await requestOfGet("api/v2/system/status")
      if (!!resp?.error) return
      sDispatch({key: "sCanTrade", value: resp})
    }
    getStatus()
  }, [])

  // useEffect(() => {
  //   const getInfoData = async () => {
  //     const resp = await requestNotError(`fight/info/v2?type=${process.env.REACT_APP_KLINE_TYPE}`)
  //     if (!!resp?.error) return
  //     sDispatch({key: "sInfoData", value: resp})
  //   }
  //   !!isLogin && getInfoData()
  // }, [sInfoReload, isLogin])

  const getVisitorData = () => {
    const visitorData = requestVisitorData()
    sDispatch({key: "sInfoData", value: visitorData})
  }

  useEffect(() => {
    isLogin ? getInfoData() : getVisitorData()
  }, [isLogin, sInfoReload, sChangeOfBalance])

  // useEffect(() => {
  //   const getOrders = async () => {
  //     const response = await requestNotError("fight/playingOrder", {
  //       status: "PROGRESS",
  //       type: process.env.REACT_APP_KLINE_TYPE,
  //       duration: Number(process.env.REACT_APP_KLINE_LENGTH) + 5
  //     })
  //     if (!!response?.error) return
  //     const _newOrders = !!response ? response : []
  //     if (_newOrders.length === 0) return
  //     const _orders = !!sProgressOrders ? sProgressOrders : []
  //     if (_orders.length === 0) {
  //       sDispatch({key: "sProgressOrders", value: _newOrders})
  //     } else {
  //       const _order = _orders[_orders.length - 1]
  //       const _newOrder = _newOrders[_newOrders.length - 1]
  //       if (_newOrder.createTime < _order.createTime) return
  //       sDispatch({key: "sProgressOrders", value: _newOrders})
  //     }
  //   }
  //   if (!sProgressOrders || sProgressOrders.length === 0) return
  //   const isExists = sProgressOrders.some(order => order.isLocal === true)
  //   if (!isExists) return
  //   getOrders()
  // }, [sProgressOrders])

  // useEffect(() => {
  //   async function getInfoData() {
  //     const resp = await requestNotError(`fight/info/v2?type=${process.env.REACT_APP_KLINE_TYPE}`)
  //     !!resp && !resp?.error && setData(resp)
  //   }
  //   const interval = setInterval(function() {
  //     if (!!isLogin) {
  //       getInfoData()
  //     }
  //   }, 10000) 
  //   return () => {
  //     clearInterval(interval)
  //   }
  // }, [isLogin])
  
  return (
    !sInfoData ? 
      <div className="home-page">
        <Knavbar />
        <Kshadow />
      </div>
    : 
      <div className="home-page">
        <Knavbar />
        {
          !!isLogin && <Kmenu />
        }
        {
          sTabIndex === 0 ? 
            <>
              <Kinfo />
              <Kechart />
              <Kbet /> 
            </>
          :
            <Kliquidity />
        }
        <Kshadow />
      </div>
  )
}
export default withRouter(IndexPage)