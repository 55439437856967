import title from                 "./assets/title.png"
import bsc from                   "./assets/bsc.png"
import error from                 "./assets/error.png"
import gold from                  "./assets/gold.png"
import goldGif from               "./assets/gold.gif"
import avatar from                "./assets/avatar.png"
import close from                 "./assets/close.png"
import back from                  "./assets/back.png"
import record from                "./assets/record.png"
import transferRecord from        "./assets/transferRecord.png"
import transferin from            "./assets/transferin.png"
import transferin2 from           "./assets/transferin2.png"
import transferin3 from           "./assets/transferin3.png"
import transferout from           "./assets/transferout.png"
import transferout2 from          "./assets/transferout2.png"
import reset from                 "./assets/reset.png"
import up from                    "./assets/up.png"
import up2 from                   "./assets/up2.png"
import upStart from               "./assets/up-start.png"
import upEnd from                 "./assets/up-end.png"
import down from                  "./assets/down.png"
import down2 from                 "./assets/down2.png"
import downStart from             "./assets/down-start.png"
import downEnd from               "./assets/down-end.png"
import bull from                  "./assets/bull.png"
import bull2 from                 "./assets/bull2.png"
import bear from                  "./assets/bear.png"
import bear2 from                 "./assets/bear2.png"
import ongoing from               "./assets/ongoing.png"
import win from                   "./assets/win.png"
import winGif from                "./assets/win.gif"
import lose from                  "./assets/lose.png"
import add from                   "./assets/add.png"
import reduce from                "./assets/reduce.png"
import arrowBottom from           "./assets/arrow-bottom.png"
import arrowRight from            "./assets/arrow-right.png"
import flower from                "./assets/flower.png"
import link from                  "./assets/link.png"
import select from                "./assets/select.png"
import empty1 from                "./assets/empty1.png"
import empty2 from                "./assets/empty2.png"
import guide from                 "./assets/guide.png"
import guide2 from                "./assets/guide2.png"
import referral from              "./assets/referral.png"
import add2 from                  "./assets/add2.png"
import remove from                "./assets/remove.png"

const IMAGE = {
  title, 
  bsc,
  error,
  gold,
  avatar, 
  close, 
  back, 
  record,
  goldGif, 
  transferin, 
  transferin2,
  transferin3, 
  transferout, 
  transferout2,
  transferRecord,
  reset, 
  up, 
  up2,
  upStart,
  upEnd,
  down, 
  down2,
  downStart,
  downEnd, 
  bull,
  bull2, 
  bear,
  bear2,
  ongoing,
  win,
  winGif,
  lose,
  add,
  reduce,
  arrowBottom, 
  arrowRight, 
  flower,
  link,
  select, 
  empty1, 
  empty2, 
  guide, 
  guide2,
  referral,
  add2,
  remove
}

export default IMAGE