import React, { useState } from "react"
import { useLocation } from "react-router-dom"
import { useLocalStore, useSessionStore } from "@/store"
import { IMAGE } from "@/assets"
import { Button, Toast } from "antd-mobile"
import { useRoute, useEthers, useRequest } from "@/hook"
import { useTranslation } from "react-i18next"
import { theString } from "@/util"
import { Kpopup } from "@/component"
import "./styles.less"

const IndexComponent = React.memo(({ 
  title, 
  fixed, 
  hidden 
}) => {
  const { t } = useTranslation()
  const { lState } = useLocalStore()
  const { sState } = useSessionStore()
  const { requestNotError, request } = useRequest()
  const { back } = useRoute()
  const location = useLocation()
  const { connectWallet, disconnectWallet } = useEthers()
  const { lToken, lChainId } = lState
  const { sMetaMaskAddress } = sState
  const { pathname } = location
  const isLogin = !!lToken && !!sMetaMaskAddress
  const isHome = pathname.startsWith("/room")
  const [visible, setVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [inviteVisible, setInviteVisible] = useState(false)
  const [inviteValue, setInviteValue] = useState("")
  const [inviteLoading, setInviteLoading] = useState(false)

  const onConnect = async () => {
    setLoading(true)
    const walletResp = await connectWallet()
    setLoading(false)
    if (!!walletResp) {
      const resp = await requestNotError(`fight/info/v2?type=${process.env.REACT_APP_KLINE_TYPE}`)
      if (!!resp?.error || !!resp?.invitedUserAddress) {
        window.location.reload()
      } else {
        setInviteVisible(true)
      }
    }
  }

  const onBind = async () => {
    setInviteLoading(true)
    const resp = await request("app/account/bindInvitor", {
      invitorWalletAddress: inviteValue
    })
    setInviteLoading(false)
    if (!!resp?.error) return
    setInviteVisible(false)
    Toast.show({
      icon: "success",
      content: t("component.bet.t1")
    })
  }

  return (
    <>
      <div className={`navbar-component ${fixed ? 'navbar-fixed' : ''}`} style={{ opacity: hidden ? '0' : '1.0' }}>
      {
        isHome ? 
          <img src={IMAGE.title} alt="" className="logo" draggable="false" />
        : 
          <img src={IMAGE.back} alt="" className="back" onClick={() => back()} />
      }
      <p className="navbar-title">{ title }</p>
      {
        isHome ? 
          !!isLogin ? 
            <div className="address-container" onClick={() => setVisible(true)}>
              <img src={ Number(process.env.REACT_APP_NETWORK_ID) === Number(lChainId) ? IMAGE.bsc : IMAGE.error } alt="" draggable="false" />
              <p>{ theString.ellipsisMiddle(sMetaMaskAddress, 6) }</p>
            </div>
          : 
            <Button 
              className="connect-button" 
              loading={loading} 
              onClick={() => onConnect()}
            >{t("component.navbar.connect")}</Button>
        : 
          <></>
      } 
      </div>
      <Kpopup 
        visible={visible} 
        setVisible={setVisible} 
        type="disconnect" 
        onConfirm={() => {
          disconnectWallet()
          setVisible(false)
        }}
      />
      <Kpopup 
        visible={inviteVisible} 
        setVisible={setInviteVisible} 
        value={inviteValue} 
        setValue={setInviteValue} 
        loading={inviteLoading} 
        type="setInvitor"
        onConfirm={onBind}
        onCancle={() => setInviteVisible(false)}
      />
    </>
  )
})
export default IndexComponent
