/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"
import { withRouter, useParams } from "react-router-dom"
import { IMAGE } from "@/assets"
import { Button, Toast } from "antd-mobile"
import { Kechart, Kpopup } from "@/component"
import { useRoute, useEthers } from "@/hook"
import { useTranslation } from "react-i18next"
import { useSessionStore } from "@/store"
import { useRequest } from "@/hook"
import "./styles.less"

const IndexPage = ({ history }) => {
  const { t } = useTranslation()
  const { inviter } = useParams()
  const { push } = useRoute()
  const { connectWallet } = useEthers()
  const { requestNotError, request } = useRequest()
  const { sState, sDispatch } = useSessionStore()
  const { sMetaMaskAddress, sInfoData } = sState
  const [inviteVisible, setInviteVisible] = useState(false)
  const [inviteValue, setInviteValue] = useState("")
  const [inviteLoading, setInviteLoading] = useState(false)

  const onConnect = async () => {
    const walletResp = await connectWallet()
    if (!!walletResp) {
      const resp = await requestNotError(`fight/info/v2?type=${process.env.REACT_APP_KLINE_TYPE}`)
      if (!!resp?.error || !!resp?.invitedUserAddress) {
        push("/room")
      } else {
        setInviteVisible(true)
      }
    } else {
      push("/room")
    }
  }

  useEffect(() => {
    const getInfoData = async () => {
      const resp = await requestNotError(`fight/info/v2?type=${process.env.REACT_APP_KLINE_TYPE}`)
      if (!!resp?.error) return
      sDispatch({key: "sInfoData", value: resp})
    }
    !!sMetaMaskAddress && getInfoData()
  }, [sMetaMaskAddress])

  const onBind = async () => {
    setInviteLoading(true)
    const resp = await request("app/account/bindInvitor", {
      invitorWalletAddress: inviteValue
    })
    setInviteLoading(false)
    if (!!resp?.error) return
    setInviteVisible(false)
    Toast.show({
      icon: "success",
      content: t("component.bet.t1")
    })
    setTimeout(() => {
      const _route = !!inviter ? `/room/${inviter}` : "/room"
      push(_route)
    }, 1000)
  }

  return (
    <div className="start-page">
      <div className="bg" />
      <img src={IMAGE.title} alt="" draggable="false" className="pic-title" />
      <p className="pic-content">{t("page.start.title")}</p>
      <p className="pic-desc">{t("page.start.content", { length: Number(process.env.REACT_APP_KLINE_LENGTH) })}</p>
      <div className="kline-div">
        <Kechart />
      </div>
      <Button className="push-button" onClick={() => {
        if (!!sMetaMaskAddress && !!sInfoData && !sInfoData?.invitedUserAddress) {
          setInviteVisible(true)
        } else {
          const _route = !!inviter ? `/room/${inviter}` : "/room"
          push(_route)
        }
        
      }}>{t("page.start.join")}</Button>
      <p className="push-label" onClick={() => onConnect()}>{t("page.start.login")}</p>
      <Kpopup 
        visible={inviteVisible} 
        setVisible={setInviteVisible} 
        value={inviteValue} 
        setValue={setInviteValue} 
        loading={inviteLoading} 
        type="setInvitor"
        onConfirm={onBind}
        onCancle={() => {
          const _route = !!inviter ? `/room/${inviter}` : "/room"
          push(_route)
        }}
      />
    </div>
  )
}
export default withRouter(IndexPage)