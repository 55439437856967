import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom"
import { Start, Home, Record, Rules, Risk, Referral, Notfound } from "@/page"
import "@/i18n"
import "./App.less"

const App = () => {
  
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Redirect to="/join" />
        </Route>
        <Route exact path="/join/:inviter?"><Start /></Route>
        <Route path="/room/:inviter?"><Home /></Route>
        <Route path="/record/:asset/:type"><Record /></Route>
        <Route path="/rules"><Rules /></Route>
        <Route path="/risk"><Risk /></Route>
        <Route path="/referral"><Referral /></Route>
        <Route path="/*"><Notfound /></Route>
      </Switch>
    </Router>
  )
}

export default App