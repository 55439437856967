import { storage } from "@/util"

const reducer = (state, action) => {
  storage.sets(action.key, action.value)
  switch(action.key) {
    case "sMetaMaskAddress":
      return {...state, sMetaMaskAddress: action.value }
    case "sProgressOrders":
      return { ...state, sProgressOrders: action.value }
    case "sFinishedOrders":
      return { ...state, sFinishedOrders: action.value }
    case "sInfoReload":
      return { ...state, sInfoReload: action.value }
    case "sInfoData":
      return { ...state, sInfoData: action.value }
    case "sKlineDatas":
      return { ...state, sKlineDatas: action.value }
    case "sTabIndex":
      return { ...state, sTabIndex: action.value }
    case "sChangeOfBalance":
      return { ...state, sChangeOfBalance: action.value }
    case "sDisableTag":
      return { ...state, sDisableTag: action.value }
    case "sCanTrade":
      return { ...state, sCanTrade: action.value }
    default:
      return state
  }
}

export default reducer