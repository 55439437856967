const mathematics = {

  getDecimalLength(num) {
    const str = num.toString()
    if (str.indexOf('.') !== -1) {
      return str.length - str.indexOf('.') - 1
    } else {
      return 0
    }
  },

  add(a, b) {
    const c = Math.max(this.getDecimalLength(a), this.getDecimalLength(b))
    const d = Math.pow(10, c)
    return (a * d + b * d) / d
  },

  floor(number, digit = 2) {
    if (!number) return 0
    const _digit = Math.pow(10, digit)
    const _number = parseFloat(number)
    const __number = parseFloat(Math.floor(_number * _digit) / _digit)
    return __number
  },

  localeString(number, digit = 3) {
    if (!number && number !== 0) {
      return "~"
    }
    const _number = parseFloat(number)
    const numbers = `${_number}`.split(".")
    if (numbers.length === 1) {
      return parseFloat(numbers[0]).toLocaleString()
    } else {
      const _number1 = numbers[0]
      const __number1 = parseFloat(_number1).toLocaleString()
      const _number2 = numbers[1]
      const _digit = Math.pow(10, digit)
      const _number = parseFloat(`0.${_number2}`)
      const __number = parseFloat(Math.floor(_number * _digit) / _digit)
      const _numbers = `${__number}`.split(".")
      if (_numbers.length === 1) {
        return `${__number1}` 
      } else {
        const ___number = _numbers[1]
        return `${__number1}.${___number}`
      }
    }
  },

  floorString(amount, digit = 3) {
    const _amount = parseFloat(amount)
    const _digit = Math.pow(10, digit)
    const __amount = parseFloat(Math.floor(_amount * _digit) / _digit)
    return __amount.toString()
  },

  ceilString(amount) {
    const _amount = parseFloat(amount)
    const __amount = parseFloat(Math.ceil(_amount * 1000) / 1000)
    return __amount.toString()
  }

}

export default mathematics