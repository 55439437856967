import { ethers } from "ethers"

export const web3Get = async (contractAddress, contractABI, method, args) => {
  const provider = new ethers.providers.Web3Provider(window.ethereum)
  const signer = provider.getSigner()
  const contract = new ethers.Contract(contractAddress, contractABI, signer)
  try {
    const resp = await contract[method](...args)
    return {
      success: true,
      response: resp
    }
  } catch (error) {
    return {
      success: false,
      response: error
    }
  }
}

export const web3Post = async (contractAddress, contractABI, method, args) => {
  const provider = new ethers.providers.Web3Provider(window.ethereum)
  const signer = provider.getSigner()
  const contract = new ethers.Contract(contractAddress, contractABI, signer)
  try {
    const tx = await contract[method](...args)
    await tx.wait()
    return {
      success: true,
      response: tx
    }
  } catch (error) {
    return {
      success: false,
      response: error
    }
  }
}