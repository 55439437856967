import LanguageDetector from "i18next-browser-languagedetector"
import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import enTrans from "./locales/en.json"
import zhTrans from "./locales/zh.json"

const language = navigator.language || navigator.userLanguage
const lng = 
  !language ?
    "en"
  : language.startsWith("zh") ?
    "zh"
  :
    "en"

i18n.use(LanguageDetector)
.use(initReactI18next)
.init({
  resources: {
    en: {
      translation: enTrans,
    },
    zh: {
      translation: zhTrans,
    },
  },
  lng: lng,
  fallbackLng: "en",
  debug: false,
  interpolation: {
    escapeValue: false,
  },
})

export default i18n