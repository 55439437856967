/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react"
import { Button, Input, Toast } from "antd-mobile"
import { useTranslation } from "react-i18next"
import { mathematics } from "@/util"
import { useWeb3Request } from "@/hook"
import { useSessionStore } from "@/store"
import { ETHERS } from "@/ethers"
import { ABI } from "@/assets"
import "./styles.less"

const IndexComponent = () => {
  const { t } = useTranslation()
  const { sState } = useSessionStore()
  const { getWeb3RequestNotError, postWeb3Request } = useWeb3Request()
  const { sMetaMaskAddress, sInfoData, sTgbsLpPoolContract, sCanTrade } = sState
  const [added, setAdded] = useState(0)
  const [totalLPAmount, setTotalLPAmount] = useState(0)
  const [shareOfPool1, setShareOfPool1] = useState(0)
  const [shareOfPool2, setShareOfPool2] = useState(0)
  const [liquidity, setLiquidity] = useState(0)
  const [available, setAvailable] = useState(0)
  const [amount, setAmount] = useState("")
  const [reload, setReload] = useState(false)
  const [addLoading, setAddLoading] = useState(false)
  const [removeLoading, setRemoveLoading] = useState(false)

  useEffect(() => {
    const getAdded = async () => {
      const resp = await getWeb3RequestNotError(
        sTgbsLpPoolContract, 
        ABI.TGBSLPPOOL, 
        "lpInfo", 
        [sMetaMaskAddress]
      )
      const _added = resp === null ? 0 : ETHERS.format(resp)
      setAdded(_added)
    }
    getAdded()
  }, [sMetaMaskAddress, reload])

  useEffect(() => {
    const getTotalLPAmount = async () => {
      const resp = await getWeb3RequestNotError(
        sTgbsLpPoolContract, 
        ABI.TGBSLPPOOL, 
        "totalLPAmount", 
        []
      )
      const _totalLPAmount = resp === null ? 0 : ETHERS.format(resp)
      setTotalLPAmount(_totalLPAmount)
    }
    getTotalLPAmount()
  }, [reload])

  useEffect(() => {
    if (added !== 0 && totalLPAmount !== 0) {
      const _shareOfPool1 = added / totalLPAmount
      setShareOfPool1(_shareOfPool1)
    } else {
      setShareOfPool1(0)
    }
  }, [added, totalLPAmount])

  useEffect(() => {
    if (!!amount && Number(amount) !== 0) {
      const _shareOfPool2 = mathematics.floor(Number(amount) * 100 / (liquidity + Number(amount)), 6)
      setShareOfPool2(_shareOfPool2)
    } else {
      setShareOfPool2(0)
    }
  }, [amount, liquidity])

  useEffect(() => {
    const getLiquidity = async () => {
      const addressResp = await getWeb3RequestNotError(
        sTgbsLpPoolContract, 
        ABI.TGBSLPPOOL, 
        "tgbsTokenAddress", 
        []
      )
      if (addressResp === null) {
        setLiquidity(0)
        return
      }
      const resp = await getWeb3RequestNotError(
        addressResp, 
        ABI.TOKENERC20, 
        "balanceOf", 
        [sTgbsLpPoolContract]
      )
      const _liquidity = resp === null ? 0 : ETHERS.format(resp)
      setLiquidity(_liquidity)
    }
    getLiquidity()
  }, [reload])

  useEffect(() => {
    const getAvailable = async () => {
      const addressResp = await getWeb3RequestNotError(
        sTgbsLpPoolContract, 
        ABI.TGBSLPPOOL, 
        "tgbsTokenAddress", 
        []
      )
      if (addressResp === null) {
        setLiquidity(0)
        return
      }
      const resp = await getWeb3RequestNotError(
        addressResp, 
        ABI.TOKENERC20, 
        "balanceOf", 
        [sMetaMaskAddress]
      )
      const _available = resp === null ? 0 : ETHERS.format(resp)
      setAvailable(_available)
    }
    getAvailable()
  }, [sMetaMaskAddress, reload])

  const onAdd = async () => {
    setAddLoading(true)
    const addressResp = await getWeb3RequestNotError(
      sTgbsLpPoolContract, 
      ABI.TGBSLPPOOL, 
      "tgbsTokenAddress", 
      []
    )
    if (addressResp === null) {
      setAddLoading(false)
      return
    }
    const approveResp = await postWeb3Request(
      addressResp, 
      ABI.TOKENERC20, 
      "approve", 
      [sTgbsLpPoolContract, ETHERS.bignumber(Math.ceil(amount), 18)]
    )
    if (approveResp === null) {
      setAddLoading(false)
      return
    } 
    const addResp = await postWeb3Request(
      sTgbsLpPoolContract, 
      ABI.TGBSLPPOOL, 
      "addLiquidity", 
      [ETHERS.bignumber(amount, 18)]
    )
    setAddLoading(false)
    if (addResp === null) return
    setReload(!reload)
    Toast.show({
      icon: 'success',
      content: t("component.liquidity.t1")
    })
  }

  const onRemove = async () => {
    setRemoveLoading(true)
    const resp = await postWeb3Request(
      sTgbsLpPoolContract, 
      ABI.TGBSLPPOOL, 
      "removeLiquidity", 
      []
    )
    setRemoveLoading(false)
    if (resp === null) return
    setReload(!reload)
    Toast.show({
      icon: 'success',
      content: t("component.liquidity.t2")
    })
  }

  return (
    <div className="liquidity-component">
      <div className="container">
        {/* <div className="data-item">
          <p className="key">{t("component.liquidity.t3")}</p>
          <p className="value">{`${mathematics.localeString(added)} ${sInfoData?.asset}`}</p>
        </div> */}
        <div className="data-item">
          <p className="key">{t("component.liquidity.t4")}</p>
          <p className="value">{`${mathematics.floor(shareOfPool1 * 100, 6)}%`}</p>
        </div>
        <div className="data-item">
          <p className="key">{t("component.liquidity.t5")}</p>
          <p className="value light">{`${mathematics.localeString(liquidity)} ${sInfoData?.asset}`}</p>
        </div>
      </div>
      <Button 
        className="button remove-button"
        disabled={added <= 0 || !sCanTrade}
        loading={removeLoading}
        onClick={onRemove}
      >{`- ${t("component.liquidity.t8")} ${mathematics.localeString((shareOfPool1 * liquidity).toFixed(2))} ${sInfoData?.asset}`}</Button>
      <p className="session-title">{t("component.liquidity.t6")}</p>
      <div className="container amount-container">
        <div className="input-container">
          <div className="input-div">
            <Input 
              className="input" 
              placeholder="0" 
              value={amount}
              onChange={(value) => {
                const regex = /^\d*\.?\d{0,3}$/
                if (regex.test(value)) {
                  setAmount(value)
                }
              }} 
            />
            <h1 className="asset">{sInfoData?.asset}</h1>
          </div>
          <div className="max-div">
            <h2>{`${ mathematics.localeString(available)} ${t("component.liquidity.t7")}`}</h2>
            <h1 onClick={() => setAmount(mathematics.floorString(available))}>{t("component.info.max")}</h1>
          </div>
        </div>
        <div className="data-item">
          <p className="key">{t("component.liquidity.t4")}</p>
          <p className="value">{`${shareOfPool2}%`}</p>
        </div>
      </div>
      <Button 
        className="button add-button"
        disabled={!amount || Number(amount) <= 0 || !sCanTrade}
        loading={addLoading}
        onClick={onAdd}
      >{`+ ${t("component.liquidity.t9")}`}</Button>
    </div>
  )
}
export default IndexComponent
