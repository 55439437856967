import { useState } from "react"
import { Toast } from "antd-mobile"
import { web3Get, web3Post } from "@/service/web3"
import { useSessionStore } from "@/store"

const useWeb3Request = () => {
  const { sState } = useSessionStore()
  const { sMetaMaskAddress } = sState
  const [loading, setLoading] = useState(false)

  const getWeb3Request = async (contractAddress, contractABI, method, args, showError=true) => {
    if (!sMetaMaskAddress) return null
    setLoading(true)
    const result = await web3Get(contractAddress, contractABI, method, args)
    setLoading(false)
    const { success, response } = result
    if (success) {
      return response
    } else {
      if (showError) Toast.show({
        icon: "fail",
        content: response.message
      })
      return null
    }
  }

  const getWeb3RequestNotError = async (contractAddress, contractABI, method, args) => {
    return getWeb3Request(contractAddress, contractABI, method, args, false)
  }

  const postWeb3Request = async (contractAddress, contractABI, method, args, showError=true) => {
    if (!sMetaMaskAddress) return null
    setLoading(true)
    const result = await web3Post(contractAddress, contractABI, method, args)
    setLoading(false)
    const { success, response } = result
    if (success) {
      return response
    } else {
      if (showError && response.code !== 4001 && response.code !== "ACTION_REJECTED") Toast.show({
        icon: "fail",
        content: response.message
      })
      return null
    }
  }

  const postWeb3RequestNotError = async (contractAddress, contractABI, method, args) => {
    return postWeb3Request(contractAddress, contractABI, method, args, false)
  }

  return {
    loading,
    getWeb3Request,
    getWeb3RequestNotError,
    postWeb3Request,
    postWeb3RequestNotError
  }
}

export default useWeb3Request