import { useLocalStore, useSessionStore } from "@/store"

const useClear = () => {
  const { lDispatch } = useLocalStore()
  const { sDispatch } = useSessionStore()

  const cleanUserInfo = () => {
    lDispatch({key: "lAddress", value: ""})
    lDispatch({key: "lToken", value: ""})
    lDispatch({key: "lChainId", value: ""})
    lDispatch({key: "lCachedProvider", value: false})
    sDispatch({key: "sMetaMaskAddress", value: ""})
    sDispatch({key: "sTabIndex", value: 0})
    sDispatch({key: "sInfoData", value: null})
  }

  return {
    cleanUserInfo
  }
}

export default useClear