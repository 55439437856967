/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"
import { Button, Popup, Input, Toast } from "antd-mobile"
import { useTranslation } from "react-i18next"
import { useLocalStore, useSessionStore } from "@/store"
import { Kpopup } from "@/component"
import { useRequest, useWeb3Request, useEthers } from "@/hook"
import { storage, mathematics } from "@/util"
import { ETHERS } from "@/ethers"
import { IMAGE, ABI } from "@/assets"
import "./styles.less"

const TRANSFERTYPE = {
  in: 0, 
  out: 1
}

const IndexComponent = () => {
  const { t } = useTranslation()
  const { lState } = useLocalStore()
  const { sState, sDispatch } = useSessionStore()
  const { connectWallet } = useEthers()
  const { request, requestNotError } = useRequest()
  const { getWeb3RequestNotError, postWeb3Request } = useWeb3Request()
  const { lToken } = lState
  const { sMetaMaskAddress, sTgbsChargePoolContract, sInfoData, sInfoReload, sCanTrade } = sState
  const isLogin = !!lToken && !!sMetaMaskAddress
  const [transferType, setTransferType] = useState(TRANSFERTYPE.in)
  const [visible, setVisible] = useState(false)
  const [amount, setAmount] = useState("")
  const [tgbsAmount, setTgbsAmount] = useState("")
  const [loading, setLoading] = useState(false)
  const [reload, setReload] = useState(false)
  const [isPlaying, setIsPlaying] = useState(false)
  const [gifKey, setGifKey] = useState(0)
  const [guideVisible, setGuideVisible] = useState(false)
  const [withdrawVisible, setWithdrawVisible] = useState(false)
  const [inviteVisible, setInviteVisible] = useState(false)
  const [inviteValue, setInviteValue] = useState("")
  const [inviteLoading, setInviteLoading] = useState(false)

  useEffect(() => {
    const getTgbsAmount = async () => {
      const addressResp = await getWeb3RequestNotError(
        sTgbsChargePoolContract, 
        ABI.TGBSCHARGEPOOL, 
        "tgbsTokenAddress", 
        []
      )
      if (addressResp === null) return
      const resp = await getWeb3RequestNotError(
        addressResp, 
        ABI.TOKENERC20, 
        "balanceOf", 
        [sMetaMaskAddress]
      )
      const _tgbsAmount = resp === null ? 0 : ETHERS.format(resp)
      setTgbsAmount(_tgbsAmount)
    }
    transferType === TRANSFERTYPE.in && getTgbsAmount()
  }, [sMetaMaskAddress, transferType, reload, visible])

  useEffect(() => {
    setGifKey(gifKey + 1)
    setIsPlaying(true)
    setTimeout(() => {
      setIsPlaying(false)
    }, 2000)
  }, [sInfoData?.balance])

  const onClose = () => {
    setVisible(false)
    setAmount("")
  }

  const recharge = async () => {
    setLoading(true)
    const addressResp = await getWeb3RequestNotError(
      sTgbsChargePoolContract, 
      ABI.TGBSCHARGEPOOL, 
      "tgbsTokenAddress", 
      []
    )
    if (addressResp === null) {
      setLoading(false)
      return
    }
    const approveResp = await postWeb3Request(
      addressResp, 
      ABI.TOKENERC20, 
      "approve", 
      [sTgbsChargePoolContract, ETHERS.bignumber(Math.ceil(amount), 18)]
    )
    if (approveResp === null) {
      setLoading(false)
      return
    } 
    const resp = await postWeb3Request(
      sTgbsChargePoolContract, 
      ABI.TGBSCHARGEPOOL, 
      "charge", 
      [ETHERS.bignumber(amount, 18)]
    )
    setLoading(false)
    if (resp === null) return
    setReload(!reload)
    Toast.show({
      icon: "success",
      content: t("component.info.t3")
    })
    setTimeout(() => {
      setVisible(false)
      sDispatch({key: "sChangeOfBalance", value: true})
      sDispatch({key: "sInfoReload", value: !sInfoReload})
    }, 1000)
  }

  const withdraw = async () => {
    setLoading(true)
    const resp = await request("fight/withdraw", { amount })
    setLoading(false)
    if (!!resp?.error) return
    Toast.show({
      icon: "success",
      content: t("component.info.t4")
    })
    setTimeout(() => {
      setVisible(false)
      sDispatch({key: "sChangeOfBalance", value: true})
      sDispatch({key: "sInfoReload", value: !sInfoReload})
    }, 1000)
  }

  const onBind = async () => {
    setInviteLoading(true)
    const resp = await request("app/account/bindInvitor", {
      invitorWalletAddress: inviteValue
    })
    setInviteLoading(false)
    if (!!resp?.error) return
    setInviteVisible(false)
    Toast.show({
      icon: "success",
      content: t("component.bet.t1")
    })
  }

  const onConnectWallet = async () => {
    const walletResp = await connectWallet()
    if (!!walletResp) {
      const resp = await requestNotError(`fight/info/v2?type=${process.env.REACT_APP_KLINE_TYPE}`)
      if (!!resp?.error || !!resp?.invitedUserAddress) {
        setGuideVisible(false)
      } else {
        setInviteVisible(true)
      }
    } else {
      setGuideVisible(false)
    }
  }

  return (
    <>
      <div className={`info-component ${sInfoData?.balance === 0 ? 'light-border' : ''}`}>
        <div className="container">
          <div className="coin-div">
            <img key={gifKey} src={IMAGE.goldGif} alt="" draggable="false" className="coin-gif" style={{ display: isPlaying ? 'block' : 'none' }} />
            <img src={IMAGE.gold} alt="" draggable="false" className="coin" />
          </div>
          <div className="balance-div">
          {
            isLogin ? 
            sInfoData?.balance === 0 ? 
                <h1>{t("component.info.t1")}</h1>
              : 
                <h1>{`${mathematics.localeString(sInfoData?.balance)} ${sInfoData?.asset}`}</h1>
            : 
              <h1>{`${mathematics.localeString(sInfoData?.balance)} ${sInfoData?.asset} ${t("component.info.t5")}`}</h1>
          }
          {
            isLogin ?
              sInfoData?.balance === 0 ? 
                <h2 className="tap-desc">{t("component.info.t2")}</h2>
              : 
                <h2>{`${mathematics.localeString(sInfoData?.withdrawable)} ${t("component.info.withdrawable")}`}</h2>
            : 
              <></>
          }
          </div>
          {
            isLogin ?
              <div className="button-div">
              {
                (sInfoData?.balance === 0 ? [IMAGE.transferin3] : [IMAGE.transferin, IMAGE.transferout]).map((item, index) => {
                  return <img src={item} alt="" draggable="false" style={{ opacity: !sCanTrade ? '0.5' : '1.0' }} key={index} onClick={() => {
                    if (!sCanTrade) return 
                    setTransferType(index)
                    setVisible(true)
                  }} />
                })
              }
              </div>
            : <div className="button-div">
              <img src={IMAGE.reset} alt="" draggable="false" onClick={() => {
                setGuideVisible(true)
              }} />
            </div>
          }
        </div>
        <Popup
          visible={visible}
          closeOnMaskClick
          destroyOnClose
          bodyClassName="transfer-popup"
          onClose={() => onClose()}
        >
          <div className="tool">
            <img src={IMAGE.close} alt="" draggable="false" onClick={() => onClose()} />
            <p>
            { 
              transferType === TRANSFERTYPE.in ? 
                t("component.info.deposit") 
              : 
                t("component.info.withdraw") 
            }
            </p>
          </div>
          <div className="amount-container">
            <div className="input-container">
              <div className="input-div">
                <Input 
                  className="input" 
                  placeholder="0" 
                  value={amount}
                  onChange={(value) => {
                    const regex = /^\d*\.?\d{0,3}$/
                    if (regex.test(value)) {
                      setAmount(value)
                    }
                  }} 
                />
                <h1 className="asset">{ sInfoData?.asset }</h1>
              </div>
              <div className="max-div">
                <h2>{`${ mathematics.localeString(transferType === TRANSFERTYPE.in ? tgbsAmount : sInfoData?.withdrawable)} ${t("component.bet.available")}`}</h2>
                <h1 onClick={() => setAmount(mathematics.floorString(transferType === TRANSFERTYPE.in ? tgbsAmount || 0 : sInfoData?.withdrawable || 0))}>{t("component.info.max")}</h1>
              </div>
            </div>
          </div>
          <Button 
            block
            className="next-button"
            disabled={
              !amount || Number(amount) <= 0 ? 
                true 
              : transferType === TRANSFERTYPE.in && Number(amount) > Number(mathematics.floorString(tgbsAmount)) ?
                true
              : transferType === TRANSFERTYPE.out && Number(amount) > Number(mathematics.floorString(sInfoData?.withdrawable)) ?
                true
              : 
                false
            }
            loading={loading}
            onClick={() => {
              transferType === TRANSFERTYPE.in ? 
                recharge()
              :
                withdraw()
            }}
          >{t("component.info.next")}</Button>
        </Popup>
      </div>
      <Kpopup 
        type="reset"
        visible={guideVisible} 
        setVisible={setGuideVisible} 
        // onConfirm={() => {
        //   const virtualOrders = storage.getl("virtualOrders") || []
        //   const _virtualOrders = virtualOrders.filter(virtualOrder => virtualOrder.userResult === "")
        //   const __virtualOrders = _virtualOrders.map(function(item) {
        //     return { ...item, disable: true }
        //   })
        //   storage.setl("virtualOrders", __virtualOrders)
        //   sDispatch({key: "sInfoReload", value: !sInfoReload})
        // }} 
        onConfirm={() => onConnectWallet()}
      />
      <Kpopup 
        type="withdraw"
        visible={withdrawVisible} 
        setVisible={setWithdrawVisible}  
      />
      <Kpopup 
        visible={inviteVisible} 
        setVisible={setInviteVisible} 
        value={inviteValue} 
        setValue={setInviteValue} 
        loading={inviteLoading} 
        type="setInvitor"
        onConfirm={onBind}
        onCancle={() => setInviteVisible(false)}
      />
    </>
  )
}
export default IndexComponent
