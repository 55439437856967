/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react"
import { Toast } from "antd-mobile"
import { post, get } from "@/service"
import { useClear } from "@/hook"
import { useTranslation } from "react-i18next"
import { storage } from "@/util"

const useRequest = (url, params={}) => {
  const { t } = useTranslation()
  const { cleanUserInfo } = useClear()
  const [data, setData] = useState(null)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  const request = async (_url, _params={}, _load=true, _error=true, _toast=false) => {
    try {
      _toast && Toast.show({
        icon: "loading",
        content: t("hook.useRequest.loading"),
        duration: 0
      })
      _load && setLoading(true)
      const resp = await post(_url, _params)
      _load && setLoading(false)
      _toast && Toast.clear()
      setData(resp)
      return resp
    } catch (error) {
      if (error.code === 90004) {
        cleanUserInfo()
        setTimeout(() => {
          window.location.reload()
        }, 500)
      } else {
        setError(error)
        _load && setLoading(false)
        const errorMessage = 
          error.message.includes("timeout") ?
            t("hook.useRequest.t1")
          : error.message.includes("Network Error") ?
            t("hook.useRequest.t2")
          : !!error.message && error.message.length > 0 ?
            error.message
          : 
            t("hook.useRequest.t3")
        _error && Toast.show({
          icon: 'fail',
          content: errorMessage
        })
        return {
          error: true,
          desc: error
        } 
      }
    }
  }

  const requestOfGet = async (_url, _params={}, _load=true, _error=true, _toast=false) => {
    try {
      _toast && Toast.show({
        icon: "loading",
        content: t("hook.useRequest.loading"),
        duration: 0
      })
      _load && setLoading(true)
      const resp = await get(_url, _params)
      _load && setLoading(false)
      _toast && Toast.clear()
      setData(resp)
      return resp
    } catch (error) {
      if (error.code === 90004) {
        cleanUserInfo()
        setTimeout(() => {
          window.location.reload()
        }, 500)
      } else {
        setError(error)
        _load && setLoading(false)
        const errorMessage = 
          error.message.includes("timeout") ?
            t("hook.useRequest.t1")
          : error.message.includes("Network Error") ?
            t("hook.useRequest.t2")
          : !!error.message && error.message.length > 0 ?
            error.message
          : 
            t("hook.useRequest.t3")
        _error && Toast.show({
          icon: 'fail',
          content: errorMessage
        })
        return {
          error: true,
          desc: error
        } 
      }
    }
  }

  const requestNotLoading = async (_url, _params={}) => {
    return request(_url, _params, false, true, false)
  }

  const requestNotError = async (_url, _params={}) => {
    return request(_url, _params, true, false, false)
  }

  const requestWithToast = async (_url, _params={}) => {
    return request(_url, _params, true, true, true)
  }
  
  const requestNotLoadingAndError = async (_url, _params={}) => {
    return request(_url, _params, false, false, false)
  }

  const requestWithToastNotError = async (_url, _params={}) => {
    return request(_url, _params, true, false, true)
  }

  const requestVisitorData = () => {
    const virtualOrders = storage.getl("virtualOrders") || []
    let balance = 10000
    const payOutRate = 0.85
    if (virtualOrders.length > 0) {
      virtualOrders.forEach(element => {
        const amount = 
          element.disable ? 
            0
          : element.userResult === "WIN" ? 
            element.amount * payOutRate 
          : 
            element.amount * -1
        balance += amount
      })
    }
    return {
      balance: balance,
      withdrawable: 0,
      symbol: "BTCAMM",
      asset: "AMM_DEMO",
      payOutRate: payOutRate
    }
  }

  useEffect(() => {
    !!url && request(url, params)
  }, [url])

  return {
    data, loading, error, 
    request,
    requestOfGet,
    requestNotLoading,
    requestNotError,
    requestWithToast,
    requestNotLoadingAndError,
    requestWithToastNotError,
    requestVisitorData,
  }
}

export default useRequest