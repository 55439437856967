import React from "react"
import "./styles.less"

const IndexComponent = React.memo(() => {
  
  return (
    <div className="shadow-component" />
  )
})
export default IndexComponent
