/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react"
import { withRouter, useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { PullToRefresh, InfiniteScroll, DotLoading } from "antd-mobile"
import { useSessionStore } from "@/store"
import { Knavbar, Kshadow } from "@/component"
import { useRequest, useConstant, useClass } from "@/hook"
import { mathematics } from "@/util"
import moment from "moment"
import { IMAGE } from "@/assets"
import "./styles.less"

const pageSize = 20

const IndexPage = ({ history }) => {
  const { t } = useTranslation()
  const { asset, type } = useParams()
  const { classNames } = useClass()
  const { sState, sDispatch } = useSessionStore()
  const { STATUSRECORD } = useConstant()
  const { request, loading } = useRequest()
  const { sInfoData, sTabIndex } = sState
  const [page, setPage] = useState(1)
  const [data, setData] = useState([])
  const [last, setLast] = useState(true)
  const title = type === "battle" ? t("page.record.title.battle") : type === "transfer" ? t("page.record.title.transfer") : ""

  useEffect(() => {
    async function getListData() {
      const url = 
          type === "battle" ? "fight/fight_records/v2" 
        : type === "transfer" ?
          sTabIndex === 1 ? 
            "fight/lp_records"
          :  
            "fight/transferlist/v2"
        : ""
      const params =
          type === "battle" ? {
            "page": page,
            "pageSize": pageSize,
            "type": asset
          } : type === "transfer" && sTabIndex === 1 ? {
            page, pageSize
          } : {
            page, pageSize, asset
          }
      const resp = await request(url, params)
      if (!!resp && !resp?.error) {
        const { content, last } = resp
        const _data = page > 1 ? data.concat() : []
        const __data = _data.concat(content)
        setData(__data)
        setLast(last)
      }
    }
    if (!!asset && !!type) {
      getListData()  
    }
  }, [page, last, asset, type, sTabIndex])

  useEffect(() => {
    setPage(1)
    setData([])
    setLast(true)
  }, [sTabIndex])

  function onRefresh() {
    if (loading) return
    setPage(1)
    setLast(true)
  }

  function loadMore() {
    if (loading || last) return
    setTimeout(() => {
      const _page = page + 1
      setPage(_page)
    }, 500)
  }
   
  return (
    <div className="record-page">
      <Knavbar title={title} />
      {
        type === "battle" && <div className="total-div">
          <p className="key">{t("page.record.total")}</p>
          <p className="value">{mathematics.localeString(sInfoData?.betAmount)}</p>
        </div>
      }
      {
        type === "transfer" && (
          <div className="tabs">
          {
            [t("component.menu.trading"), t("component.menu.liquidity")].map((tabItem, index) => {
              return <div 
                className={classNames([
                  sTabIndex === index && "tab-active"
                ])}
                key={index}
                onClick={() => sDispatch({key: "sTabIndex", value: index})}
              >{ tabItem }</div>
            })
          }
          </div>
        )
      }
      <PullToRefresh onRefresh={onRefresh} renderText={status => {
        return <div>{STATUSRECORD[status]}</div>
      }}>
        {
          type === "battle" ?
            !!data && data.length > 0 ? <div className="data-list">
            {
              data.map((item, index) => {
                return <div key={index} className="battle-data-item">
                <div className="item-head-div">
                  <div className="amount-div">
                    <img src={item.direction === "BULL" ? IMAGE.up : IMAGE.down} alt="" draggable="false" />
                    <h1>{`${mathematics.floorString(item.amount)} ${item.asset}`}</h1>
                  </div>
                  {
                    item.result === 'UNKNOWN' ? <img src={IMAGE.ongoing} alt="" draggable="false" className="ongoing-image" /> : <div className="image-div">
                      <img src={IMAGE.bull2} alt="" draggable="false" className="image-bull" style={{
                        zIndex: (item.direction === "BULL" && item.result === 'LOST') || (item.direction === "BEAR" && item.result === 'WIN') ? 1 : 2,
                        mixBlendMode: (item.direction === "BULL" && item.result === 'LOST') || (item.direction === "BEAR" && item.result === 'WIN') ? 'luminosity' : 'normal'
                      }} />
                      <img src={IMAGE.bear2} alt="" draggable="false" className="image-bear" style={{
                        zIndex: (item.direction === "BULL" && item.result === 'WIN') || (item.direction === "BEAR" && item.result === 'LOST') ? 1 : 2,
                        mixBlendMode: (item.direction === "BULL" && item.result === 'WIN') || (item.direction === "BEAR" && item.result === 'LOST') ? 'luminosity' : 'normal'
                      }} />
                    </div>
                  }
                  <h1 className="amount">{ !!item.resultAmount ? `${mathematics.floorString(item.resultAmount)} ${item.asset}` : '~' }</h1>
                </div>
                <div className="item-content-div">
                  <h2 style={{ flex: '1' }}>{`$${mathematics.floorString(item.startPrice, 2)}`}</h2>
                  <h2>{ item.result === 'WIN' ? '🎊' : item.result === 'LOST' ? '🤕' : item.result === 'DRAW' ? '🤷‍♂️' : '' }</h2>
                  <h2 style={{ flex: '1', textAlign: 'right' }}>{ !!item.endPrice ? `$${mathematics.floorString(item.endPrice, 2)}` : '~'}</h2>
                </div>
                <div className="item-content-div">
                  <h2 style={{ flex: '1' }}>{moment(item.startTime).format("YYYY-MM-DD HH:mm")}</h2>
                  <h2>{ item.result === 'WIN' ? 'Win' : item.result === 'LOST' ? 'Lose' : item.result === 'DRAW' ? 'Draw' : '' }</h2>
                  <h2 style={{ flex: '1', textAlign: 'right' }}>{ !!item.endTime ? moment(item.endTime).format("YYYY-MM-DD HH:mm") : '~' }</h2>
                </div>
              </div>
              })
            }
            </div>
            : !loading ? <div className="empty">
              <img src={IMAGE.empty1} alt="" draggable="false" />
              <p>{t("page.record.empty.battle")}</p>
            </div>
            : <></>
          : type === "transfer" ?
            !!data && data.length > 0 ? <div className="data-list">
            {
              data.map((item, index) => {
                return  <div key={index} className="transfer-data-item">
                  {
                    sTabIndex === 1 ? (
                      <img src={item.type === "ADD_LIQUIDITY" ? IMAGE.add2 : IMAGE.remove} alt="" draggable="false" className="image"/>
                    ) : (
                      <img src={item.direction === "IN" ? IMAGE.transferin2 : IMAGE.transferout2} alt="" draggable="false" className="image"/>
                    )
                  }
                  <div className="content-div">
                    {
                      sTabIndex === 1 ? (
                        <div className="content-item">
                          <h1>{ item.type === "REMOVE_LIQUIDITY" ? t("component.liquidity.t8") : t("component.liquidity.t9") }</h1>
                          <h1>{`${item.type === "ADD_LIQUIDITY" ? '+' : '-'} ${Number(Math.abs(item.amount)).toLocaleString()} ${item.asset}`} </h1>
                        </div>
                      ) : (
                        <div className="content-item">
                          {
                            item.status === "IN_REVIEW" ? 
                              <h1 style={{ color: "#EE9C37" }}>{`⏱ ${t("page.record.review")}`}</h1> :
                            item.status === "FINISHED" ?
                              <h1 style={{ color: "#fff" }}>{`✅ ${t("page.record.completed")}`}</h1> :
                            item.status === "REJECTED" ?
                              <h1 style={{ color: "#F25263" }}>{`⛔ ${t("page.record.rejected")}`}</h1> :
                              <h1>{ item.status }</h1>
                          }
                          <h1 style={{ color: item.status === "REJECTED" ? 'rgba(255, 255, 255, 0.25)' : '#fff' }}>{`${item.direction === "IN" ? '+' : '-'} ${Number(Math.abs(item.amount)).toLocaleString()} ${item.asset === "PT" ? "pUSD" : item.asset}`}</h1>
                        </div>
                      )
                    }
                    <div className="content-item">
                      <h2>{ `${moment(item.time).format("YYYY-MM-DD HH:mm")}` }</h2>
                      <h2>{ !!item.bounsAmount ? `Bonus: ${item.bounsAmount} ${item.asset}` : ''}</h2>
                    </div>
                  </div>
                </div>
              })
            }
            </div>
            : !loading ? <div className="empty">
              <img src={IMAGE.empty2} alt="" draggable="false" />
              {
                sTabIndex === 1 ? <p>{t("page.record.empty.transfer2")}</p> : <p>{t("page.record.empty.transfer")}</p>
              }
            </div>
            : <></>
          : <></>
        }
        <InfiniteScroll loadMore={loadMore} hasMore={!last}>
        {
          (!last || loading) ? <DotLoading /> : <div></div>
        }
        </InfiniteScroll>
      </PullToRefresh>
      <Kshadow />
    </div>
  )
}
export default withRouter(IndexPage)