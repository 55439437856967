import { useTranslation } from "react-i18next"

const useConstant = () => {
  const { t } = useTranslation()

  const STATUSRECORD = {
    pulling: t("hook.useConstant.pulling"),
    canRelease: t("hook.useConstant.canRelease"),
    refreshing: t("hook.useConstant.refreshing"),
    complete: t("hook.useConstant.complete"),
  }

  return {
    STATUSRECORD
  }
}

export default useConstant