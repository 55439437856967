/* eslint-disable no-unused-vars */
import React from "react"
import { IMAGE } from "@/assets"
import { Popup, Button, Toast, Input } from "antd-mobile"
import { useRoute, useEthers } from "@/hook"
import { useTranslation } from "react-i18next"
import { storage } from "@/util"
import CopyToClipboard from "react-copy-to-clipboard"
import "./styles.less"

const IndexComponent = React.memo(({ 
  visible, 
  setVisible, 
  type, 
  onConfirm,
  onCancle,
  value,
  setValue,
  loading,
}) => {
  const { t } = useTranslation()
  const { push } = useRoute()
  const { connectWallet } = useEthers()

  return (
    <Popup
      visible={visible}
      closeOnMaskClick={false}
      destroyOnClose
      bodyClassName="guide-popup"
      onClose={() => setVisible(false)}
    >
      {
        type !== "disconnect" && type !== "setInvitor" && <img src={IMAGE.guide} alt="" draggable="false" />
      }
      <p>{ 
        type === "beat" ? 
          t("component.guide.desc") 
        : type === "reset" ? 
          t("component.guide.resetDesc") 
        : type === "withdraw" ?
          t("component.guide.withdrawDesc") 
        : type === "disconnect" ?
          t("component.guide.disconnect") 
        : type === "setInvitor" ?
          t("component.guide.setInvitor") 
        :
          ""
      }</p>
      {/* {
        type === "reset" && <p className="link">https://guessing.tsp.network/join</p>
      } */}
      {
        type === "setInvitor" && <Input 
          className="input" 
          placeholder={t("component.guide.invitor")} 
          value={value}
          onChange={(value) => {
            setValue(value)
          }}
        />
      }
      {
        type === "beat" ? 
          <div className="buttons">
            <Button shape="rounded" className="button confirm" onClick={() => connectWallet()}>{t("component.guide.confirm")}</Button>
            <Button shape="rounded" className="button cancle" onClick={() => {
              setVisible(false)
            }}>{t("component.guide.cancle")}</Button>
          </div>
        : type === "reset" ?
          <div className="buttons">
            <CopyToClipboard
              text={"https://guessing.tsp.network/join"}
              // onCopy={() => Toast.show({
              //   icon: 'success',
              //   content: t("component.info.copied")
              // })}
            >
              <Button shape="rounded" className="button confirm" onClick={() => {
                onConfirm()
                setVisible(false)
              }}>{t("component.guide.share")}</Button>
            </CopyToClipboard>
            <Button shape="rounded" className="button cancle" onClick={() => {
              setVisible(false)
            }}>{t("component.guide.later")}</Button>
          </div>
        : type === "withdraw" ?
          <div className="buttons">
            <Button shape="rounded" className="button confirm" onClick={() => {
              push("/referral")
              storage.setl("isStoragedWithdraw", true) 
            }}>{t("component.guide.invite")}</Button>
            <Button shape="rounded" className="button cancle" onClick={() => {
              setVisible(false)
              storage.setl("isStoragedWithdraw", true) 
            }}>{t("component.guide.skip")}</Button>
          </div>
        : type === "disconnect" ?
            <div className="buttons">
              <Button shape="rounded" className="button confirm" onClick={() => onConfirm()}>{t("component.guide.disco")}</Button>
              <Button shape="rounded" className="button cancle" onClick={() => setVisible(false)}>{t("component.guide.cancle2")}</Button>
            </div>
        : type === "setInvitor" ?
          <div className="buttons">
            <Button shape="rounded" disabled={!value} loading={loading} className="button confirm" onClick={() => onConfirm()}>{t("component.guide.confirm2")}</Button>
            <Button shape="rounded" className="button cancle" onClick={() => {
              !!onCancle && onCancle()
              setVisible(false)
            }}>{t("component.guide.cancle2")}</Button>
          </div>
        :
            <></>
      }
    </Popup>
  )
})
export default IndexComponent
