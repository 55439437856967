import React, { createContext, useReducer, useContext } from "react"
import reducer from "./reducer"
import { storage } from "@/util"

export const initState = {
  sMetaMaskAddress: storage.gets("sMetaMaskAddress"),
  sProgressOrders: storage.gets("sProgressOrders"),
  sFinishedOrders: storage.gets("sFinishedOrders"),
  sInfoReload: storage.gets("sInfoReload"),
  sInfoData: storage.gets("sInfoData"),
  sKlineDatas: storage.gets("sKlineDatas"),
  sTabIndex: storage.gets("sTabIndex") || 0,
  sChangeOfBalance: storage.gets("sChangeOfBalance") || false,
  sDisableTag: storage.gets("sDisableTag") || 0,
  sTgbsChargePoolContract: process.env.REACT_APP_TGBS_CHARGE_POOL_CONTRACT,
  sTgbsLpPoolContract: process.env.REACT_APP_TGBS_LP_POOL_CONTRACT,
  sCanTrade: storage.gets("sCanTrade") || false,
}

const ConfigCtx = createContext(null)

export const Provider = props => {
  const [sState, sDispatch] = useReducer(reducer, initState)
  return <ConfigCtx.Provider value={{ sState, sDispatch }}>{ props.children }</ConfigCtx.Provider>
}

export const useSessionStore = () => useContext(ConfigCtx)