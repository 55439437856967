/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useParams } from "react-router-dom"
import { useEffect } from "react"
import { 
  useClear, 
  useRequest, 
} from "@/hook"
import { useLocalStore, useSessionStore } from "@/store"
import { 
  connect,
  exchange,
  ETHERS
} from "@/ethers"

const useEthers = () => {
  const { inviter } = useParams()
  const { lDispatch, lState } = useLocalStore()
  const { sDispatch, sState } = useSessionStore()
  const { cleanUserInfo } = useClear()
  const { request } = useRequest()
  const { lCachedProvider, lAddress, lToken } = lState
  const { sMetaMaskAddress } = sState

  const connectWallet = async () => {
    const resp = await connect()
    if (!resp) return false
    const { signer, provider } = resp
    const network = await provider.getNetwork()
    const address = await signer.getAddress()
    if (!!lAddress && lAddress.toLowerCase() === address.toLowerCase() && !!lToken) {
      sDispatch({key: "sMetaMaskAddress", value: address})
      return true
    }
    const invitorWalletAddress = !!inviter ? inviter : null
    const signatureResp = await request("app/account/loginSignature", { address })
    if (!!signatureResp?.error) return false
    try {
      const msg = await signer.signMessage(signatureResp)
      const loginResp = await request("app/account/loginWeb3", { address, msg, invitorWalletAddress })
      if (!!loginResp?.error) return false
      const chainId = network.chainId
      lDispatch({key: "lCachedProvider", value: true})
      sDispatch({key: "sMetaMaskAddress", value: address})
      lDispatch({key: "lChainId", value: chainId})
      lDispatch({key: "lToken", value: loginResp.token})
      lDispatch({key: "lAddress", value: loginResp.address})
      return true
    } catch (error) {
      return false
    }
  }

  const disconnectWallet = () => {
    cleanUserInfo()
    setTimeout(() => {
      window.location.reload()
    }, 500)
  }

  const exchangeNetwork = async (_) => {
    if (typeof window.ethereum === "undefined") return
    const resp = await exchange(_)
    return resp
  }

  // useEffect(() => {
  //   !!lCachedProvider && !sMetaMaskAddress && connectWallet()
  // }, [])

  useEffect(() => {
    const handleAccountsChanged = async (accounts) => {
      if (accounts.length === 0) {
        disconnectWallet()
      } else {
        const address = accounts[0]
        sDispatch({key: "sMetaMaskAddress", value: address})
      }
    }

    const handleChainChanged = (_) => {
      const chainId = ETHERS.parse(_)
      lDispatch({key: "lChainId", value: chainId})
    }

    if (typeof window.ethereum === "undefined") return
    window.ethereum.on("accountsChanged", handleAccountsChanged)
    window.ethereum.on("chainChanged", handleChainChanged)

    return () => {
      window.ethereum.removeListener("accountsChanged", handleAccountsChanged)
      window.ethereum.removeListener("chainChanged", handleChainChanged)
    }
  }, [])

  return {
    connectWallet,
    disconnectWallet,
    exchangeNetwork
  }
}

export default useEthers