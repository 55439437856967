const theString = {

  desensitizeEmail(email) {
    if (!email || typeof email !== "string") return ""
    const parts = email.split('@')
    if (parts.length !== 2) return email
    let localPart = parts[0]
    let desensitizedLocalPart = 
      localPart.length <= 2  ? 
        localPart.replace(/./g, '*') 
      : 
      localPart.slice(0, 2) + "****" + localPart.slice(-1)
    return `${desensitizedLocalPart}@${parts[1]}`
  },

  ellipsisMiddle(str, maxLength) {
    if (!str) return ""
    if (str.length <= maxLength) return str
    const leftPart = str.slice(0, maxLength / 2)
    const rightPart = str.slice(-maxLength / 2)
    return leftPart + '...' + rightPart
  },
  
}

export default theString