import React from "react"
import { useTranslation } from "react-i18next"
import { useSessionStore } from "@/store"
import { useRoute, useClass } from "@/hook"
import { IMAGE } from "@/assets"
import "./styles.less"

const IndexComponent = React.memo(() => {
  const { t } = useTranslation()
  const { sState, sDispatch } = useSessionStore()
  const { push } = useRoute()
  const { classNames } = useClass()
  const { sTabIndex } = sState

  return (
   <div className="menu-component">
      <div className="tabs">
      {
        [t("component.menu.trading"), t("component.menu.liquidity")].map((tabItem, index) => {
          return <div 
            className={classNames([
              sTabIndex === index && "tab-active"
            ])}
            key={index}
            onClick={() => sDispatch({key: "sTabIndex", value: index})}
          >{ tabItem }</div>
        })
      }
      </div>
      <div className="buttons">
        <img src={IMAGE.referral} alt="" onClick={() => push("/referral")} />
        <img src={IMAGE.transferRecord} alt="" onClick={() => push("/record/AMM/transfer")} />
        <img src={IMAGE.record} alt="" onClick={() => push(`/record/${process.env.REACT_APP_KLINE_TYPE}/battle`)} />
        <img src={IMAGE.guide2} alt="" onClick={() => push("/rules")} />
      </div>
   </div>
  )
})
export default IndexComponent
